import React, { useState } from "react"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline"
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material"
// import { useTheme } from '@mui/material/styles';
import { withSubtheme } from "../../../StarberryComponentsMui"
import defaults from "./defaults"
import "./defaults.css"

const AccordionComp = withSubtheme(
    props => {
        const { data, theme } = props
        // const theme = useTheme();
        const [accordionStates, setAccordionStates] = useState({})

        const handleAccordionToggle = accordionId => {
            setAccordionStates(prev => ({
                ...prev,
                [accordionId]: !prev[accordionId],
            }))
        }

        return (
            <div>
                <h4 dangerouslySetInnerHTML={{ __html: data.introText }} />
                {data.accordion.map((d, key) => (
                    <Accordion
                        classes={{ root: "accordion-comp" }}
                        key={key}
                        expanded={accordionStates[key]}
                        onChange={() => handleAccordionToggle(key)}
                        sx={{
                            marginBottom: "10px",
                            border: `1px solid ${theme.palette.grey.grey6}`,
                        }}
                    >
                        <AccordionSummary
                            expandIcon={
                                <IconButton>
                                    {accordionStates[key] ? (
                                        <RemoveCircleOutlineIcon />
                                    ) : (
                                        <AddCircleOutlineIcon />
                                    )}
                                </IconButton>
                            }
                            aria-controls={`${key}-content`}
                            id={`${key}-header`}
                            className={`accordion-title`}
                        >
                            <Typography sx={{ fontWeight: "bold" }}>
                                {d.title}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <ul>
                                    {d.description.map(desc => {
                                        return (
                                            <li
                                                dangerouslySetInnerHTML={{
                                                    __html: desc,
                                                }}
                                            />
                                        )
                                    })}
                                </ul>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
                <Typography className="table-title" variant="h5" component="h5">
                    Bidding Status Definitions
                </Typography>
                <TableContainer>
                    <Table className="table">
                        <TableHead>
                            <TableRow className="table-row ">
                                <TableCell className="table-cell table-head">
                                    Status
                                </TableCell>
                                <TableCell className="table-cell table-head">
                                    Meaning
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.status.map((row, key) => (
                                <TableRow className="table-row " key={key}>
                                    <TableCell className="table-cell">
                                        {row.status}
                                    </TableCell>
                                    <TableCell className="table-cell">
                                        {row.meaning}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        )
    },
    "accordionComp",
    defaults
)

export default AccordionComp
