// ref - https://www.gatsbyjs.com/docs/how-to/routing/client-only-routes-and-user-authentication/
import React from "react"
import { doRedirect } from "../utils"
import { useAuthState } from "../../../services";
import { MYACCOUNT_LOGIN, MYACCOUNT_2FACTOR_AUTH, TWOFACTOR_AUTH } from "../../../constants/urls";
import { getData, storeData, getUserSession } from "../../../services/store/utils"
import AppFeaturesContext from "../../../StarberryComponentsMui/AppFeaturesContext"
import { featureRoutesMap } from "../../../StarberryComponentsMui/features"

const PrivateRoute = ({ component: Component, location, ...props }) => {
    const { path } = props;
    const { appFeatures, allFeaturesEnabled } = React.useContext(AppFeaturesContext);

    const { services } = useAuthState()
    const isAuthenticated = services.isAuthenticated()
    const isBrowser = typeof window !== "undefined"

    // // This is like a user session heartbeat when the page gets reloaded check for the user session
    // React.useEffect(() => {
    //   services.getUserProfile();
    // }, [])

    // Redirect to login page
    if (!isAuthenticated && location.pathname !== MYACCOUNT_LOGIN) {

        // Check for return url in url query string
        let urlParams, returnURL, storeParams;
        if (isBrowser) {
            urlParams = new URLSearchParams(window.location.search);
            returnURL = urlParams.get('return') || getData('auth_return');
            storeParams = urlParams.get('store_params') || getData('auth_params');

            if (storeParams) {
                storeData('auth_params', storeParams, 'auth');
            }
        }

        if (returnURL){
            storeData('auth_return', returnURL, 'auth');
        } else {
            returnURL = `${location.pathname}${location.hash}`;
            if (returnURL !== '/') {
                storeData('auth_return', `${location.pathname}${location.hash}`, 'auth');
            }
        }

        doRedirect(MYACCOUNT_LOGIN)
        return null
    }

    const isFeatureAvailable = path === '/' || allFeaturesEnabled || Object.keys(featureRoutesMap)
        .filter(feature => appFeatures.includes(feature))
        .find(feature => {
            return !!featureRoutesMap[feature].test(path);
        });

    if (!isFeatureAvailable) {
        doRedirect('/');
        return null;
    }

    /**
     * Look for 2factor authentication. User tokens are stored in cookie for 2factor authetication we are also store the seession in session storage. 
     * If seesion storage has the value user can see all the auth page. Otherwise system send OTP via email to login using OTP token.
    */

    if (TWOFACTOR_AUTH && !getUserSession()) {
        doRedirect(MYACCOUNT_2FACTOR_AUTH);
        return <>Loading...</>
    }

    return <Component {...props} />;
}
export default PrivateRoute
