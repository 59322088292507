import * as React from 'react';
import Accordions from '../accordion';

import Container from '@mui/material/Container';
import Stack from "@mui/material/Stack";

import GlobalLayout from "../GlobalLayout";
import PageTitle from '../pageTitle';
import { useAuthState } from '../../../services';


const FaqWrapComp = () => {

  const { services:authServices } = useAuthState();
  const pageTitle = `FAQ`;

  console.log('authServices', authServices)

  React.useEffect(() => {
      authServices.updateState({pageTitle:pageTitle, pageRightCustomComponent: null, pageRightDrawer: null, pageLeftIcon: false});
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <Accordions />
  )

}
const AccordionExpandIcon = () => {
  return (
    <GlobalLayout>
      <Container maxWidth="xl">
        <PageTitle>Faq</PageTitle>
            <Stack my={1} direction="column" spacing={2} alignItems="center">
              <FaqWrapComp />
            </Stack>
      </Container>
    </GlobalLayout>
  );
}

export  default React.memo(AccordionExpandIcon);
